import { createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../api/AxiosInstance';
export const fetchUserById = createAsyncThunk(
    'user/fetchUserById',
    async ({ userId, token }, { rejectWithValue }) => {
        try {
            const response = await AxiosInstance.get(`/portal/users/${userId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Error fetching user');
        }
    }
);
