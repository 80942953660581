import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import userReducer from '../features/users/userSlice';
import psychologistReducer from '../features/psychologists/psychologistSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        psychologist: psychologistReducer
    },
    devTools: true,
});
