import { createSlice } from '@reduxjs/toolkit';
import { registerPsychologist } from './psychologistThunk';

const psychologistSlice = createSlice({
    name: 'psychologist',
    initialState: {
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null,
        successMessage: null,
    },
    reducers: {
        clearState: (state) => {
            state.status = 'idle';
            state.error = null;
            state.successMessage = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(registerPsychologist.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(registerPsychologist.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.successMessage = action.payload.message || 'Registration successful';
            })
            .addCase(registerPsychologist.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const { clearState } = psychologistSlice.actions;
export default psychologistSlice.reducer;
