import axios from "axios";
const baseURL = `/api/`;

const AxiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 10000,
    headers: {
        "Content-Type": "application/json",
        accept: "application/json",
    },
})
AxiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        console.log(token)
        if (token) {
            config.headers.Authorization = `Token ${token}`;
        }
        else {
            config.headers.Authorization = ``;
        }
        return config;
    })

AxiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if(error.response && error.response.status === 401){
            localStorage.removeItem('token');
        }
        return Promise.reject(error);
    }
)
export default AxiosInstance;
