import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { loginUser } from "../../features/auth/authThunks";
import { useNavigate } from "react-router-dom";
import "./Login.css";

const LoginForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user_type, status, error } = useSelector((state) => state.auth);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = (e) => {
        e.preventDefault();
        dispatch(loginUser({username, password}));
    };

    useEffect(() => {
        if (status === "succeeded") {
         navigate('/profile');
        }
    }, [status, navigate]);
    return (
        <div className="login-form">
            <h2>Welcome to NeuroscribeAI,</h2>
            <h2>Sign in to get started</h2>
            <form onSubmit={handleLogin}>
                <label htmlFor="username">Username</label>
                <input
                    type="text"
                    id="username"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="login-input"
                />
                <label htmlFor="password">Password</label>
                <input
                    type="password"
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="login-input"
                />
                <button type="submit" className="login-button">Login</button>
                <p>
                    Don't have an account?{' '}<a href="/register">Register</a>
                </p>
            </form>
            {error && <p className="error-message">{error.error}</p>}
        </div>
    );
};

export default LoginForm;
