import { createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../api/AxiosInstance';

export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async (credentials, { rejectWithValue }) => {
        try {
            const response = await AxiosInstance.post('/portal/login/', credentials);
            const { token } = response.data;
            localStorage.setItem('token', token);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
