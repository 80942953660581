import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import {store} from "./app/store"; // Adjust path to your store
import LoginForm from "./components/Login/LoginForm"; // Adjust path as needed
import RegisterPsychologist from "./components/RegisterPsychologist/RegisterPsychologist"; // Adjust path as needed
import UserProfile from "./components/UserProfile/UserProfile"; // Adjust path as needed

function App() {
  return (
      <div className="App">
        <BrowserRouter>
          <Provider store={store}>
            <Routes>
              <Route path="/" element={<LoginForm />} />
              <Route path="/register" element={<RegisterPsychologist />} />
              <Route path="/profile" element={<UserProfile />} />
            </Routes>
          </Provider>
        </BrowserRouter>
      </div>
  );
}

export default App;

