import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearUser } from '../../features/users/userSlice';
import { fetchUserById } from '../../features/users/userThunks';

const UserProfile = () => {
    const dispatch = useDispatch();
    const { user_id, status, error } = useSelector((state) => state.auth);
    const { data } = useSelector((state) => state.user); // Assuming `data` is in `state.user`

    useEffect(() => {
        if (user_id) {
            dispatch(fetchUserById({ userId: user_id }));
        }
        return () => {
            dispatch(clearUser());
        };
    }, [dispatch, user_id]);

    if (status === "loading") return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div>
            <h1>Profile</h1>
            {data ? (
                <div>
                    <p>Name: {data.first_name} {data.last_name}</p>
                    <p>Email: {data.email}</p>
                </div>
            ) : (
                <p>No user data available.</p>
            )}
        </div>
    );
};

export default UserProfile;
