import { createSlice } from '@reduxjs/toolkit';
import { loginUser } from './authThunks';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: localStorage.getItem('token') || null,
        username: null,
        user_id: null,
        user_type: null,
        status: 'idle',
        error: null
    },
    reducers: {
        logout: (state) => {
            state.token = null;
            state.status = 'idle';
            localStorage.removeItem("token"); // Remove token from localStorage on logout

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.token = action.payload.token;
                state.username = action.payload.user.username;
                state.user_id = action.payload.user_id;
                state.user_type = action.payload.user_type;
                state.status = 'succeeded';
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
