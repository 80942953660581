import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import "./RegisterPsychologist.css";
import states from "../../data/states.json"; //
import {registerPsychologist} from '../../features/psychologists/psychologistThunk'; // Adjust path
import {clearState} from "../../features/psychologists/psychologistSlice";

const RegisterPsychologistForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {status, error, successMessage} = useSelector((state) => state.psychologist);

    const credentialOptions = ["license", "degree", "student"];
    const psyFieldOptions = ["clinical", "child", "developmental", "other"];
    const psyDegreeOptions = ["ph_d", "psy_d", "ms", "ba", "bs", "other"];

    const [formData, setFormData] = useState({
        username: "",
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        credential_type: "license",
        license_number: "",
        license_state: "", // Default empty value for license state
        psy_field: "clinical",
        psy_degree: "ph_d",
        supervisor: "",
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(registerPsychologist(dataToSend));
    };
    // בוחרים את הקוד של המדינה לפי השם המלא
    const selectedState = states.find(
        (state) => state.name === formData.license_state
    );

    const dataToSend = {
        ...formData,
        license_state: selectedState ? selectedState.code : "", // שולחים את הקוד
    };

    useEffect(() => {
        if (status === 'succeeded') {
            navigate('/'); // Redirect to the homepage
            dispatch(clearState()); // Clear state to avoid stale success message
        }
    }, [status, navigate, dispatch]);

    return (
        <div>
            <h2>Clinician Registration</h2>
            {status === 'loading' && <p>Loading...</p>}
            {error && (
                <ul style={{ color: 'red' }}>
                    {typeof error === 'object'
                        ? Object.keys(error).map((key) => (
                            <li key={key}>{`${error[key].join(', ')}`}</li>
                        ))
                        : <li>{error}</li>
                    }
                </ul>
            )}
            {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

            <form onSubmit={handleSubmit} className="register-form">
                <h2>Welcome to NeuroscribeAI!</h2>

                <label>
                    Username:
                    <input
                        type="text"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                    />
                </label>

                <label>
                    First Name:
                    <input
                        type="text"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        required
                    />
                </label>

                <label>
                    Last Name:
                    <input
                        type="text"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    Email:
                    <input
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    Password:
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                </label>

                <label>
                    Credential Type:
                    <select
                        name="credential_type"
                        value={formData.credential_type}
                        onChange={handleChange}
                    >
                        {credentialOptions.map((option) => (
                            <option key={option} value={option}>
                                {option.charAt(0).toUpperCase() + option.slice(1)}
                            </option>
                        ))}
                    </select>
                </label>

                <label>
                    License Number:
                    <input
                        type="text"
                        name="license_number"
                        value={formData.license_number}
                        onChange={handleChange}
                    />
                </label>

                <label>
                    License State:
                    <select
                        name="license_state"
                        value={formData.license_state}
                        onChange={handleChange}
                    >
                        <option value="">--Select a state--</option>
                        {states.map((state) => (
                            <option key={state.code} value={state.name}>
                                {state.name}
                            </option>
                        ))}
                    </select>
                </label>

                <label>
                    Field of Psychology:
                    <select
                        name="psy_field"
                        value={formData.psy_field}
                        onChange={handleChange}
                    >
                        {psyFieldOptions.map((field) => (
                            <option key={field} value={field}>
                                {field.charAt(0).toUpperCase() + field.slice(1)}
                            </option>
                        ))}
                    </select>
                </label>

                <label>
                    Degree:
                    <select
                        name="psy_degree"
                        value={formData.psy_degree}
                        onChange={handleChange}
                    >
                        {psyDegreeOptions.map((degree) => (
                            <option key={degree} value={degree}>
                                {degree.toUpperCase().replace("_", ".")}
                            </option>
                        ))}
                    </select>
                </label>

                <label>
                    Supervisor:
                    <input
                        type="text"
                        name="supervisor"
                        value={formData.supervisor}
                        onChange={handleChange}
                    />
                </label>

                <button type="submit">Register</button>
                <p>
                    Already have an account? <a href="/">sign in</a>
                </p>
            </form>
        </div>
    );
};

export default RegisterPsychologistForm;
