import { createSlice } from '@reduxjs/toolkit';
import { fetchUserById } from './userThunks';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        data: null,        // User data object
        status: 'idle',    // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null,       // Error message (if any)
    },
    reducers: {
        clearUser: (state) => {
            state.data = null;
            state.status = 'idle';
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUserById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(fetchUserById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'Failed to fetch user';
            });
    },
});

export const { clearUser } = userSlice.actions;
export default userSlice.reducer;
// Compare this snippet from src/app/store.js: