import { createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../api/AxiosInstance';

// Async thunk to register a psychologist
export const registerPsychologist = createAsyncThunk(
    'psychologist/register',
    async (psychologistData, { rejectWithValue }) => {
        try {
            const response = await AxiosInstance.post('portal/register-psychologist/', psychologistData);
            console.log(`Received response`, response);
            return response.data;
        } catch (error) {
            return rejectWithValue(
                error.response?.data || 'Failed to register psychologist. Please try again.'
            );
        }
    }
);
